<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 47 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Video</title>
    <g clip-path="url(#clip0_2285_9677)">
      <path
        d="M44.4868 6H2.57227V38H15.9087V42H31.1503V38H44.4677L44.4868 6ZM40.6764 34H6.38267V10H40.6764V34ZM31.1503 22L17.8139 30V14L31.1503 22Z"
        fill="#E9EBED"
      />
      <path
        d="M44.4868 6H2.57227V38H15.9087V42H31.1503V38H44.4677L44.4868 6ZM40.6764 34H6.38267V10H40.6764V34ZM31.1503 22L17.8139 30V14L31.1503 22Z"
        fill="url(#paint0_linear_2285_9677)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2285_9677"
        x1="291.142"
        y1="-139.636"
        x2="72.3868"
        y2="95.3237"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E19F0" />
        <stop
          offset="1"
          stop-color="#6E19F0"
          stop-opacity="0"
        />
      </linearGradient>
      <clipPath id="clip0_2285_9677">
        <rect
          width="45.7249"
          height="48"
          fill="white"
          transform="translate(0.666992)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-video',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
